<template>
  <!-- eslint-disable-next-line prettier/prettier-->
  <div class="flex flex-col h-screen justify-center items-center bg-bg-primary py-12 px-4">
    <div class="w-full max-w-3xl mx-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!--
  Usage:
  
    <template>
      <layout></layout>
    </template>

    <script>
    import layout from "@/layouts/Login.vue";
    export default {
      components: {
        layout,
      },
    };
    </script>


-->
