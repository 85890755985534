<template>
  <layout>
    <router-view> </router-view>
  </layout>
</template>

<script>
import layout from "@/layouts/Login.vue";
export default {
  components: {
    layout,
  },
};
</script>

<style></style>
